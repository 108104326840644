import { connectColors } from '@components/Connect2024/connectHelpers';
import { colors, presetStyles, breakpoints, v2Colors } from '@web-for-marketing/react-core';
import { typographyStyles } from '@web-for-marketing/react-ui';

export const videoLibraryStyles = {
    filterChip: (isSelected: boolean | undefined, connect2024?: boolean) =>
        ({
            ...typographyStyles.header4,
            whiteSpace: 'nowrap',
            border: isSelected ? 'none' : connect2024 ? `2px solid ${connectColors.white}` : 'none',
            fontWeight: 400,
            color: connect2024
                ? connectColors.white
                : isSelected
                    ? v2Colors.text.inversePrimary
                    : typographyStyles.header4.color,
            backgroundColor: isSelected
                ? connect2024
                    ? connectColors.electricBlue
                    : colors.interactive.primary
                : connect2024
                    ? connectColors.black
                    : presetStyles.overlay.activeSecondary,
            borderRadius: '100px',
            padding: '1.5rem 3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'background-color ease 500ms, color ease 500ms',
            '& svg path': {
                fill: connect2024
                    ? connectColors.white
                    : isSelected
                        ? v2Colors.text.inversePrimary
                        : v2Colors.text.activePrimary,
                transition: 'fill 500ms ease',
            },
            '&:hover': {
                cursor: 'pointer',
                '& svg path': {
                    fill: 'currentColor',
                },
                ...(isSelected
                    ? {}
                    : connect2024
                        ? {
                            backgroundColor: connectColors.white,
                            color: connectColors.electricBlue,
                        }
                        : {
                            color: v2Colors.text.inversePrimary,
                            backgroundColor: colors.interactive.primary,
                        }),
            },
            '&:focus': connect2024
                ? {
                    outline: `2px solid ${connectColors.white}`,
                    outlineOffset: '-5px',
                }
                : undefined,
        }) as const,
    cardChip: (connect?: boolean) => ({
        ...typographyStyles.body2,
        color: connect ? connectColors.white : v2Colors.text.primary,
        background: connect ? connectColors.electricBlue : presetStyles.overlay.activeSecondary,
        borderRadius: '40px',
        padding: '0.8rem 2rem',
        width: 'fit-content',
    }),
    filterContainer: {
        width: '100%',
        '& > *:not(:last-child)': {
            marginRight: '2rem',
        },
    },
    topSpacing: {
        marginTop: '6rem',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            marginTop: '3rem',
        },
    },
    videoCard: (connect?: boolean) => ({
        height: '48rem',
        border: `1px solid ${connect ? connectColors.white : v2Colors.border.primary}`,
        backgroundColor: connect ? connectColors.black : v2Colors.background.white,
        borderRadius: '8px',
        display: 'block',
        textDecoration: 'none',
        overflow: 'hidden',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            height: '100%',
        },
        [`@media (min-width: ${breakpoints.sm}px)`]: {
            '&:hover,&:focus': {
                boxShadow: presetStyles.shadow.hard,
                '& .videoCardCTA': {
                    maxHeight: 0,
                    opacity: 0,
                    transition: 'max-height 100ms ease, opacity 100ms ease',
                    marginBottom: '0 !important',
                },
                '& .videoCardDescription': {
                    maxHeight: '50rem',
                    opacity: 1,
                    transition: 'max-height 400ms ease, opacity 400ms ease',
                },
                '& img': {
                    height: '18rem',
                    transition: 'height 400ms ease',
                },
            },
        },
    }),
    videoCardCTA: {
        maxHeight: '50rem',
        opacity: 1,
        transition: 'max-height 400ms ease, opacity 400ms ease',
    },
    videoCardDescription: {
        maxHeight: 0,
        opacity: 0,
        overflow: 'hidden',
        transition: 'max-height 400ms ease, opacity 400ms ease',
    },
    videoCardContent: {
        padding: '1.6rem',
    },
    skeletonVideoCard: (connect?: boolean) => ({
        border: `1px solid ${connect ? connectColors.white : v2Colors.border.primary}`,
        padding: '1.6rem',
        borderRadius: '8px',
    }),
    imageContainer: {
        width: '100%',
        position: 'relative',
    },
    playIcon: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    cardImage: {
        height: '25rem',
        width: '100%',
        transition: 'height 400ms ease',
        borderRadius: '8px',
        objectFit: 'cover',
    },
    cardText: {
        marginTop: '2rem',
        '& > *:not(:last-child)': {
            marginBottom: '1.2rem',
        },
    },
    cardTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '2',
    },
    cardDescription: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '3',
        marginBottom: '1.2rem',
    },
    menu: {
        marginTop: '1rem !important',
        border: `1px solid ${v2Colors.border.primary}`,
        borderRadius: '8px',
        minWidth: '28rem !important',
    },
    menuItem: {
        ...typographyStyles.body1,
        color: v2Colors.text.primary,
        padding: '16px 24px',
        '&:hover, &:focus': {
            backgroundColor: v2Colors.core.cloud,
        },
    },
} as const;
