import React from 'react';

export function StarIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_217_473)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10.9829 0.5C11.3057 0.5 11.6019 0.677661 11.7526 0.961548L14.487 6.11545L20.2611 7.10895C20.5791 7.16367 20.8406 7.38896 20.9403 7.69423C21.04 7.99949 20.9617 8.33458 20.7367 8.56476L16.6526 12.7435L17.4868 18.5115C17.5327 18.8292 17.398 19.1461 17.1369 19.3347C16.8758 19.5234 16.5311 19.5528 16.2415 19.4112L10.9829 16.8399L5.72437 19.4112C5.43472 19.5528 5.09003 19.5234 4.82891 19.3347C4.56778 19.1461 4.43311 18.8292 4.47906 18.5115L5.31318 12.7435L1.2291 8.56476C1.00414 8.33458 0.92578 7.99949 1.02552 7.69423C1.12526 7.38896 1.38672 7.16367 1.70477 7.10895L7.47882 6.11545L10.2133 0.961548C10.3639 0.677661 10.6601 0.5 10.9829 0.5Z'
                    fill='currentColor'
                />
            </g>
            <defs>
                <clipPath id='clip0_217_473'>
                    <rect width='20' height='20' fill='white' transform='translate(0.98291)' />
                </clipPath>
            </defs>
        </svg>
    );
}
