import React from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { CloseIcon } from './IconComponents/CloseIcon';
import { ZoomIcon } from './IconComponents/ZoomIcon';
import { Grid, IconButton } from '@mui/material';
import { Picture } from './Picture';
import { pictureDialogStyle as classes } from './styleObjects/pictureDialogStyle';

interface PictureDialogProps {
    imagePath: string | undefined;
    mobilePath: string | undefined;
    alt: string | undefined;
    mobileAlt: string | undefined;
}

export function PictureDialog({ imagePath, mobilePath, alt, mobileAlt }: PictureDialogProps): JSX.Element {
    const [open, setOpen] = React.useState(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };
    return (
        <Grid data-testid='pictureDialog'>
            <Grid
                css={classes.overlay}
                onClick={handleOpen}
                data-testid='openModalClick'
                component='button'
                title='Click to view larger image'
            >
                <Grid css={classes.overlayText}>
                    <ZoomIcon zoomIn={true} />
                </Grid>
            </Grid>
            <Modal
                css={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                data-testid='modalComponentContainer'
            >
                <Grid css={classes.pictureDialog}>
                    <IconButton
                        color='primary'
                        onClick={handleClose}
                        aria-label='close'
                        css={classes.closeButton}
                        title='Click to close larger image'
                        size='large'
                    >
                        <CloseIcon />
                    </IconButton>
                    <Picture imagePath={imagePath} mobilePath={mobilePath} alt={alt} mobileAlt={mobileAlt} />
                </Grid>
            </Modal>
        </Grid>
    );
}
