import React from 'react';
import { CustomFormLeadshareBoxData } from '@models/section/customFormLeadshareBoxSection';
import { CallToActionBanner } from '@components/SectionComponents/CallToActionBanner';
import { GridContainer } from '@components/GeotabGridComponents/GridContainer';
import { CMSLink } from '@components/CMSLink';
import { Grid } from '@mui/material';
import { LeadShareStyle as classes } from './styleObjects/LeadShare';
import { Heading, LazyLoadContainer, Text } from '@web-for-marketing/react-ui';
import { ParseAbbreviations } from '@components/ParseAbbreviations';
import { getLinkTargetValue } from '@sections/helpers/SectionComponentHelper';
import loadable from '@loadable/component';
import OemLeadPageBg from '@images/StaticPages/DemoPage/oem-lead-page-bg@2x.jpg';

interface CustomFormLeadshareBoxProps {
    sectionData: CustomFormLeadshareBoxData;
    lazyLoad: boolean;
    headerSection: boolean;
}
const CustomFormLoadable = loadable(() => import('@components/SectionComponents/CustomForm'));

const defaultGradient =
    'linear-gradient(-285deg, rgb(37, 71, 123) 0%, rgba(37, 71, 123, 0) 0%, rgba(0, 128, 178, 0.95) 100%)';

export function CustomFormLeadshareBox({
    sectionData,
    lazyLoad,
    headerSection,
}: CustomFormLeadshareBoxProps): JSX.Element {
    return (
        <div>
            <CallToActionBanner
                lazyLoad={lazyLoad}
                imagePath={sectionData.bannerImage || OemLeadPageBg}
                title={sectionData.bannerTitle}
                description={sectionData.bannerDescription}
                customProps={{
                    customGradient: sectionData.customGradient || defaultGradient,
                    removeGradient: sectionData.removeGradient,
                    titleImage: sectionData.logoImage,
                    logoAltTag: sectionData.logoAlt,
                }}
                align='flex-start'
                headerBanner
            />
            <GridContainer>
                <Grid item sm={12} md={7} css={classes.demoItemContainer}>
                    <Grid item sm={12} css={classes.demoTextInnerContainer}>
                        {sectionData.contentTitle ? (
                            <Heading
                                variant='h3'
                                weight='regular'
                                component={headerSection ? 'h1' : 'h2'}
                                color='primary'
                                css={classes.textSubheader}
                            >
                                {sectionData.contentTitle}
                            </Heading>
                        ) : null}
                        {sectionData.contentSubtitle ? (
                            <Heading
                                variant='h4'
                                component={sectionData.contentTitle ? 'p' : 'h2'}
                                css={classes.subTitle}
                                color='tertiary'
                            >
                                {sectionData.contentSubtitle}
                            </Heading>
                        ) : null}
                        {sectionData.contentBody ? (
                            <Text
                                variant='body1'
                                color='tertiary'
                                dangerouslySetInnerHTML={{
                                    __html: ParseAbbreviations({
                                        content: sectionData.contentBody,
                                        stringFormat: true,
                                    }),
                                }}
                            />
                        ) : null}
                        {sectionData.videoSource ? (
                            <LazyLoadContainer lazyload={lazyLoad} css={classes.videoMedia}>
                                <iframe
                                    data-src={lazyLoad ? sectionData.videoSource : undefined}
                                    src={!lazyLoad ? sectionData.videoSource : ''}
                                    title={
                                        sectionData.bannerTitle ? `${sectionData.bannerTitle} video` : 'Leadshare video'
                                    }
                                    allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                                    allowFullScreen
                                />
                            </LazyLoadContainer>
                        ) : null}
                        {sectionData.buttonText ? (
                            <CMSLink
                                variant='contained'
                                href={sectionData.buttonLink}
                                rel='noopener noreferrer'
                                target={getLinkTargetValue(sectionData.buttonOpenNewTab)}
                                type='button'
                                css={{ marginTop: '2rem' }}
                            >
                                {sectionData.buttonText}
                            </CMSLink>
                        ) : null}
                    </Grid>
                </Grid>
                <Grid item sm={12} md={5} css={classes.demoFormContainer}>
                    <Grid item sm={12} css={classes.customFormContainer}>
                        <CustomFormLoadable sectionData={sectionData.customForm} lazyLoad={lazyLoad} embed />
                    </Grid>
                </Grid>
            </GridContainer>
        </div>
    );
}
