import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { TagFilter, VideoTag } from '@models/section/videoLibrarySection';
import { Heading, LazyLoadContainer, Text } from '@web-for-marketing/react-ui';
import { Grid, Hidden, Skeleton } from '@mui/material';
import { ExtendedPagination } from '@components/ExtendedPagination';
import { Video } from '@models/video';
import { getVideosByTagId } from '@services/videoPublicService';
import { videoLibraryStyles as classes } from './videoLibraryStyles';
import { PagedResult } from '@models/pagination';
import { TagFilterBar } from './TagFilterBar';
import { MobileTagFilterBar } from './MobileTagFilterBar';
import { VideoLibraryCard } from './VideoLibraryCard';
import { isMobileAtom } from '@atoms/appSettings';
import { useAtom } from 'jotai';
import { logError } from '@helpers/errors';

interface VideoLibraryContentProps {
    lazyLoad: boolean;
    title: string | React.ReactElement;
    description: string;
    tags: VideoTag[];
    displayTagCategories: boolean;
    connect2024?: boolean;
    headerSection?: boolean;
    scrollTopSpacing?: number;
}

export function VideoLibraryContent({
    lazyLoad,
    tags,
    title,
    description,
    displayTagCategories,
    connect2024,
    headerSection,
    scrollTopSpacing = 0,
}: VideoLibraryContentProps): JSX.Element {
    const [videos, setVideos] = useState<PagedResult<Video> | null>(null);
    const [loading, setLoading] = useState(true);
    const containerRef = useRef<null | HTMLDivElement>(null);
    const [selectedTag, setSelectedTag] = useState<TagFilter>('All');
    const [isMobile] = useAtom(isMobileAtom);
    const initialWidth = isMobile ? 'xs' : 'lg';

    const filteredTagIds = useMemo(() => {
        if (selectedTag !== 'All') {
            return [selectedTag];
        } else {
            const allTagIds = tags.map((tag) => tag.id);
            return allTagIds;
        }
    }, [selectedTag, tags]);

    const getVideos = useCallback((ids: number[], page: number): void => {
        if (ids.length) {
            setLoading(true);
            getVideosByTagId(ids, page)
                .then((result) => {
                    setVideos(result);
                })
                .catch((err) => {
                    setVideos(null);
                    logError(err, 'fetching videos by tag ids');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setVideos(null);
        }
    }, []);

    useEffect(() => {
        getVideos(filteredTagIds, 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getVideos, tags.length, selectedTag]);

    function handlePageChange(page: number): void {
        if (page !== videos?.currentPage) {
            getVideos(filteredTagIds, page);
            if (containerRef.current) {
                const { top } = containerRef.current.getBoundingClientRect();
                const scrollPosition = window.scrollY + top - (isMobile ? 0 : scrollTopSpacing);
                window.scrollTo({ top: scrollPosition });
            }
        }
    }

    return (
        <div>
            {title ? (
                <Heading
                    align='center'
                    variant='h2'
                    component={headerSection ? 'h1' : 'h2'}
                    color={connect2024 ? 'inversePrimary' : undefined}
                >
                    {title}
                </Heading>
            ) : null}
            {description ? (
                <Text align='center' css={{ marginTop: '2rem' }} color={connect2024 ? 'inversePrimary' : undefined}>
                    {description}
                </Text>
            ) : null}
            <Grid container direction='column' alignItems='center' css={{ maxWidth: '135rem' }} ref={containerRef}>
                {displayTagCategories && tags.length ? (
                    <div css={[classes.topSpacing, { width: '100%' }]}>
                        <Hidden initialWidth={initialWidth} smDown>
                            <TagFilterBar
                                connect2024={connect2024}
                                onChange={setSelectedTag}
                                selectedTag={selectedTag}
                                tags={tags}
                            />
                        </Hidden>
                        <Hidden initialWidth={initialWidth} smUp>
                            <MobileTagFilterBar
                                onChange={setSelectedTag}
                                selectedTag={selectedTag}
                                tags={tags}
                                connect2024={connect2024}
                            />
                        </Hidden>
                    </div>
                ) : null}
                {loading ? (
                    <div css={classes.topSpacing} data-testid='videoLibrarySkeletons'>
                        <Grid container spacing={8}>
                            {Array.from(new Array(6)).map((_item, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <div css={classes.skeletonVideoCard(connect2024)}>
                                        <Skeleton variant='rounded' animation='wave' height={250} />
                                        <div css={classes.cardText}>
                                            <Skeleton variant='rounded' animation='wave' height={30} width={100} />
                                            <Skeleton variant='rectangular' animation='wave' height={60} />
                                            <Skeleton variant='rectangular' animation='wave' height={20} width={80} />
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ) : videos ? (
                    <>
                        <div css={[classes.topSpacing, { width: '100%' }]}>
                            <LazyLoadContainer lazyload={lazyLoad} container spacing={8}>
                                {videos.pageRecords.map((video) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} key={video.id}>
                                            <VideoLibraryCard
                                                connect2024={connect2024}
                                                video={video}
                                                lazyLoad={lazyLoad}
                                            />
                                        </Grid>
                                    );
                                })}
                            </LazyLoadContainer>
                        </div>
                        {videos.totalRecordCount > videos.maxRecordsPerPage ? (
                            <div css={classes.topSpacing}>
                                <ExtendedPagination
                                    type='button'
                                    currentPage={videos.currentPage}
                                    maxRecordsPerPage={videos.maxRecordsPerPage}
                                    totalRecordCount={videos.totalRecordCount}
                                    onPageChange={handlePageChange}
                                    connect2024={connect2024}
                                />
                            </div>
                        ) : null}
                    </>
                ) : null}
            </Grid>
        </div>
    );
}
