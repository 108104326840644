import { colors, v2Colors } from '@web-for-marketing/react-core';

export const pageSectionStyles = {
    cardContainer: {
        backgroundColor: v2Colors.core.snow,
        position: 'relative',
    },
    hiddenText: {
        width: '100%',
        bottom: '1rem',
        left: '1rem',
        position: 'absolute',
        transition: 'color 0.25s ease',
        opacity: 0.4,
    },
    overlay: {
        backgroundColor: 'rgba(1, 23, 41, 0.3)',
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'block',
        zIndex: 9,
        '&:hover p': {
            color: v2Colors.core.snow,
            opacity: 1,
        },
    },
    errorNotification: {
        backgroundColor: colors.core.webEssentials.red,
        position: 'absolute',
        width: '30rem',
        zIndex: 9,
        bottom: '2rem',
        left: '2rem',
        borderRadius: '0.8rem',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '1rem',
    },
    warningIcon: {
        width: '5rem',
        height: '5rem',
        marginRight: '1rem',
        '& path': {
            fill: v2Colors.core.snow,
        },
    },
    hideButton: {
        display: 'none',
    },
    showButton: {
        display: 'flex',
    },
} as const;
