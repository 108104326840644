import React, { useEffect, useState } from 'react';
import { GridContainer } from '@components/GeotabGridComponents/GridContainer';
import { LazyLoadContainer, Heading, Text } from '@web-for-marketing/react-ui';
import { ChevronRight } from '@icon/ChevronRight';
import { ChevronLeft } from '@icon/ChevronLeft';
import { Grid, Button } from '@mui/material';
import { getLanguageRouteAndPrefixByCodeAndRouteKey } from '@helpers/languages';
import { useAtom } from 'jotai';
import { selectedLanguageAtom } from '@atoms/appSettings';
import { getLanguageByCode } from '@helpers/languages';
import { languageRouteTypes } from '@helpers/languageRoutes';
import { getTranslation } from '@helpers/languageTranslations';
import { useTranslation } from '@stateManagement/TranslationContext';
import { Picture } from '@components/Picture';
import { getFormattedLocalDateTime } from '@helpers/dates';
import { classes } from './styleObjects/cardCarouselStyles';
import { CMSLink } from '@components/CMSLink';
import { getCarouselByTag } from '@services/tagPublicService';
import { getCarouselByCategory } from '@services/categoryPublicService';
import { CarouselType, CarouselResult, CardCarouselSectionData } from '@models/section/cardCarouselSection';
import { getPagedPressReleaseList } from '@services/pressReleasePublicService';
import { PagedResult } from '@models/pagination';
import { TaggedSlugEntityPreview } from '@models/tag';

interface CardCarouselProps {
    sectionData: CardCarouselSectionData;
    lazyLoad: boolean;
    headerSection: boolean;
}

const entityTypeRouteTypeMap = {
    BlogArticle: languageRouteTypes.BLOG_POST,
    SuccessStoryArticle: languageRouteTypes.CASE_STUDY,
    PressReleaseArticle: languageRouteTypes.PRESS_RELEASE,
    WhitePaperArticle: languageRouteTypes.WHITE_PAPER,
    GlossaryArticle: languageRouteTypes.GLOSSARY,
    Video: languageRouteTypes.VIDEO,
};

export function CardCarousel({ sectionData, lazyLoad, headerSection }: CardCarouselProps): JSX.Element {
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const [result, setResult] = useState<CarouselResult | null>(null);
    const { selectedLanguageTranslation } = useTranslation();
    const entities = result ? result.entities : [];
    const [cardOrder, setCardOrder] = useState<number[]>([]);
    const [carouselStatus, setCarouselStatus] = useState<'forward' | 'reverse' | 'idle'>('idle');
    const carouselType = sectionData.carouselType || CarouselType.Tag;
    const title = sectionData.title;
    const isTag = carouselType === CarouselType.Tag;
    const isCategory = carouselType === CarouselType.Category;
    const isArticle = carouselType === CarouselType.Article;

    let viewAllUrl = '';

    if (sectionData.viewAllUrl) {
        viewAllUrl = sectionData.viewAllUrl;
    } else if (result) {
        const routeKey = isCategory ? 'categoryUrl' : isTag ? 'tagUrl' : isArticle ? 'pressRoomHomeUrl' : '';

        if (routeKey) {
            const routePrefix = getLanguageRouteAndPrefixByCodeAndRouteKey(selectedLanguage, routeKey);
            viewAllUrl = `${routePrefix}${result.carouselTypeEntitySlug ?? ''}`;
        }
    }

    const translatedArticleType = getTranslation(selectedLanguageTranslation, 'articleTypes');

    const friendlyType = {
        BlogArticle: translatedArticleType.blog,
        SuccessStoryArticle: translatedArticleType.caseStudy,
        PressReleaseArticle: translatedArticleType.pressRelease,
        WhitePaperArticle: translatedArticleType.whitePaper,
        Video: translatedArticleType.video,
        GlossaryArticle: translatedArticleType.glossary,
    };

    useEffect(() => {
        const getFunction = isTag ? getCarouselByTag : getCarouselByCategory;

        if (sectionData.carouselTypeEntityId) {
            getFunction(sectionData.carouselTypeEntityId, sectionData.articleType)
                .then((result) => {
                    const cardOrder = Array.from(Array(result.entities.length).keys(), (item) => item + 1);
                    setResult(result);
                    setCardOrder(cardOrder);
                })
                .catch((err) => {
                    console.error(err);
                });
        } else if (isArticle) {
            const page = 1;
            const pageSize = 6;
            const languageId = getLanguageByCode(selectedLanguage)?.id || 1;

            getPagedPressReleaseList(page, pageSize, languageId)
                .then(({ pageRecords }: PagedResult<TaggedSlugEntityPreview>) => {
                    const cardOrder = Array.from(Array(pageRecords.length).keys(), (item) => item + 1);
                    setResult({
                        entities: pageRecords,
                    });
                    setCardOrder(cardOrder);
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            setResult(null);
        }
    }, [selectedLanguage, sectionData?.carouselTypeEntityId, sectionData?.articleType, isTag, isArticle]);

    useEffect(() => {
        setCarouselStatus('idle');
    }, [cardOrder]);

    function changeToNextCard(): void {
        const newOrder = cardOrder.map((order) => {
            if (order === 1) {
                return entities.length;
            } else {
                return order - 1;
            }
        });

        setCardOrder(newOrder);
        setCarouselStatus('forward');
    }

    function changeToPreviousCard(): void {
        const newOrder = cardOrder.map((order) => {
            if (order === entities.length) {
                return 1;
            } else {
                return order + 1;
            }
        });

        setCardOrder(newOrder);
        setCarouselStatus('reverse');
    }

    return (
        <GridContainer container alignItems='center'>
            <Grid item xs={12} md={3}>
                {title ? (
                    <Heading variant='h2' component={headerSection ? 'h1' : 'h2'} css={classes.title}>
                        {title}
                    </Heading>
                ) : null}
                <Text css={classes.body}>{sectionData.body}</Text>
            </Grid>
            <Grid item xs={12} md={9}>
                <div css={classes.carouselFixedContainer}>
                    {entities.length ? (
                        <LazyLoadContainer
                            css={[
                                classes.scrollingWrapper,
                                carouselStatus === 'idle' ? classes.idle : undefined,
                                carouselStatus === 'reverse' ? classes.reverse : undefined,
                            ]}
                            lazyload={lazyLoad}
                            data-testid='cardCarousel'
                        >
                            {entities.map((article, index) => (
                                <Grid
                                    key={article.id}
                                    component={CMSLink}
                                    href={`${getLanguageRouteAndPrefixByCodeAndRouteKey(
                                        selectedLanguage,
                                        entityTypeRouteTypeMap[article.type]
                                    )}${article.slug}`}
                                    css={[classes.card, { order: cardOrder[index] }]}
                                >
                                    <Picture
                                        alt={article.cardImageAlt || ''}
                                        lazyLoad={lazyLoad}
                                        imagePath={article.cardImageUrl}
                                        mobilePath={article.mobileCardImageUrl || undefined}
                                        css={classes.picture}
                                    />
                                    <div css={classes.cardTextContainer}>
                                        {sectionData.hideCardLabel ? null : (
                                            <Text variant='body2' color='activePrimary'>
                                                {friendlyType[article.type]}
                                            </Text>
                                        )}
                                        <Heading variant='h4' component='h3' color='secondary' css={classes.cardTitle}>
                                            {article.title}
                                        </Heading>
                                        <Text variant='body2'>
                                            {getFormattedLocalDateTime(
                                                article.publishedDateUtc,
                                                'LL',
                                                selectedLanguage
                                            )}
                                        </Text>
                                    </div>
                                </Grid>
                            ))}
                        </LazyLoadContainer>
                    ) : (
                        <div data-testid='placeholderCardCarousel' css={[classes.scrollingWrapper, classes.idle]}>
                            {Array.from([1, 2, 3, 4]).map((item) => (
                                <div key={item} css={[classes.card, classes.cardPlaceholder]}>
                                    <div css={classes.cardPlaceholderImage}></div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <Grid container justifyContent='space-between' alignItems='center' css={{ marginTop: '2rem' }}>
                    {entities.length ? (
                        <>
                            <div>
                                {entities.length > 3 ? (
                                    <>
                                        <Button
                                            variant='outlined'
                                            css={classes.arrowButton}
                                            onClick={changeToPreviousCard}
                                            tabIndex={-1}
                                            aria-label='Show previous card'
                                            aria-hidden
                                            data-testid='previousCardsButton'
                                        >
                                            <ChevronLeft />
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            css={classes.arrowButton}
                                            onClick={changeToNextCard}
                                            tabIndex={-1}
                                            aria-label='Show next card'
                                            aria-hidden
                                            data-testid='nextCardsButton'
                                        >
                                            <ChevronRight />
                                        </Button>
                                    </>
                                ) : null}
                            </div>
                            <Text
                                component={CMSLink}
                                color='activeSecondary'
                                underline
                                href={viewAllUrl}
                                css={classes.viewAll}
                            >
                                {`${
                                    getTranslation(selectedLanguageTranslation, 'articleTypesAction', 'viewAll') ||
                                    'View all'
                                }`}
                            </Text>
                        </>
                    ) : null}
                </Grid>
            </Grid>
        </GridContainer>
    );
}
