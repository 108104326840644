import React from 'react';
import { Grid } from '@mui/material';
import { breakpoints, v2Colors } from '@web-for-marketing/react-core';
import { Text } from '@web-for-marketing/react-ui';
import { connectColors } from './Connect2024/connectHelpers';

interface CircleProgressBarProps {
    percentage: number;
    innerText: string | number;
    outerText: string | number;
    connect2024?: boolean;
}

const classes = {
    contentContainer: {
        textAlign: 'center',
        position: 'relative',
    },
    outerText: (connect2024?: boolean) =>
        ({
            color: connect2024 ? connectColors.white : v2Colors.core.geotab,
            fontWeight: connect2024 ? 500 : 700,
            letterSpacing: connect2024 ? '5.6px' : undefined,
            fontSize: connect2024 ? '2.8rem' : undefined,
            textTransform: connect2024 ? 'uppercase' : undefined,
            marginTop: connect2024 ? '3rem' : undefined,
            [`@media (max-width: ${breakpoints.sm}px)`]: {
                marginTop: connect2024 ? '1rem' : undefined,
            },
        }) as const,
    progress: (connect2024?: boolean) =>
        ({
            maxWidth: connect2024 ? '17rem' : undefined,
            maxHeight: connect2024 ? '17rem' : undefined,
            width: connect2024 ? '100%' : '12rem',
            height: connect2024 ? '100%' : '12rem',
        }) as const,
} as const;

const circleConfig = {
    viewBox: '0 0 38 38',
    x: 19,
    y: 19,
    r: 15.91549430918954,
};

export const CircleProgressBar = React.memo(
    ({ percentage, innerText, outerText, connect2024 }: CircleProgressBarProps) => {
        return (
            <Grid css={classes.contentContainer}>
                <svg viewBox={circleConfig.viewBox} css={classes.progress(connect2024)}>
                    <circle
                        cx={circleConfig.x}
                        cy={circleConfig.y}
                        r={circleConfig.r}
                        fill='transparent'
                        stroke={connect2024 ? connectColors.white : v2Colors.core.slate}
                        strokeOpacity={connect2024 ? '0.70' : '0.25'}
                        strokeWidth={connect2024 ? '3%' : '5%'}
                    />
                    <circle
                        cx={circleConfig.x}
                        cy={circleConfig.y}
                        r={circleConfig.r}
                        fill='transparent'
                        stroke={connect2024 ? connectColors.neonGreen : v2Colors.core.innovation}
                        strokeDasharray={`${percentage} ${100 - percentage}`}
                        strokeDashoffset='25'
                        strokeWidth={connect2024 ? '8%' : '5%'}
                        strokeLinecap={percentage === 0 ? undefined : 'round'}
                    />
                    <text
                        x={circleConfig.x}
                        y={circleConfig.y + 1}
                        textAnchor='middle'
                        dominantBaseline='middle'
                        fill={connect2024 ? connectColors.white : v2Colors.core.geotab}
                        fontSize='12'
                        fontWeight='bold'
                    >
                        {innerText}
                    </text>
                </svg>
                <Text variant='body1' css={classes.outerText(connect2024)}>
                    {outerText}
                </Text>
            </Grid>
        );
    }
);

CircleProgressBar.displayName = 'CircleProgressBar';
