import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { ParseAbbreviations } from '../ParseAbbreviations';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { CMSLink } from '@components/CMSLink';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { Checkbox, Heading, Text } from '@web-for-marketing/react-ui';
import { RichTextContent } from '@components/RichTextContent';
import { FlexAlignment, TitleBodyButtonsFormContent } from '@models/column';
import { TextColor } from '@web-for-marketing/react-ui';
import { breakpoints } from '@web-for-marketing/react-core';

interface TitleBodyButtonsProps {
    content: TitleBodyButtonsFormContent;
    textColor?: TextColor;
    headerSection?: boolean;
    horizontalAlignment: FlexAlignment;
}

const classes = {
    buttonWrapper: {
        maxWidth: '100% !important',
    },
    fullWidth: {
        width: '100%',
    },
    spacing: {
        '& > *:not(:first-child)': {
            marginTop: '3rem',
            [`@media (max-width: ${breakpoints.md}px)`]: {
                marginTop: '2rem',
            },
        },
    },
    title: (fullWidth?: boolean) => ({
        maxWidth: fullWidth ? undefined : '60rem',
    }),
};

export function TitleBodyButtons({
    content,
    textColor,
    headerSection,
    horizontalAlignment,
}: TitleBodyButtonsProps): JSX.Element {
    const [checkBoxChecked, setCheckBoxChecked] = useState(false);

    return (
        <Grid container direction='column' alignItems={horizontalAlignment} css={classes.spacing}>
            {content.title ? (
                <Heading
                    css={classes.title(content.titleFullWidth)}
                    variant={headerSection ? 'h1' : 'h2'}
                    color={textColor || 'primary'}
                    data-testid='titleBodyButtonsColumn-title'
                    dangerouslySetInnerHTML={{
                        __html: ParseAbbreviations({
                            content: content.title,
                            stringFormat: true,
                        }),
                    }}
                />
            ) : null}
            {content.body ? (
                <RichTextContent
                    variant={headerSection ? 'h4' : 'body1'}
                    color={textColor || 'secondary'}
                    weight={headerSection ? 'regular' : undefined}
                    data-testid='titleBodyButtonsColumn-body'
                    content={ParseAbbreviations({
                        content: content.body,
                        stringFormat: true,
                    })}
                />
            ) : null}
            {content.requestConsent ? (
                <div>
                    <Checkbox
                        checked={checkBoxChecked}
                        onChange={(event) => setCheckBoxChecked(event.target.checked)}
                        id={`${content.title || ''}consent-checkbox`}
                        label={
                            <Text
                                variant='body1'
                                color='tertiary'
                                dangerouslySetInnerHTML={{
                                    __html: ParseAbbreviations({
                                        content: content.checkboxText,
                                        stringFormat: true,
                                    }),
                                }}
                            />
                        }
                    />
                </div>
            ) : null}
            {content.buttons && content.buttons.length ? (
                <div css={classes.fullWidth}>
                    <Grid container spacing={3} justifyContent={horizontalAlignment}>
                        {content.buttons.map(
                            (
                                {
                                    buttonText,
                                    buttonLinkPath,
                                    buttonType,
                                    buttonTarget,
                                    buttonClassName,
                                    ariaLabel,
                                    isPopout,
                                    popoutCustomSection,
                                    params = [],
                                },
                                index
                            ) =>
                                buttonText ? (
                                    <Grid
                                        item
                                        xs={12}
                                        md='auto'
                                        css={classes.buttonWrapper}
                                        key={`${index}${buttonText}${popoutCustomSection}`}
                                    >
                                        {isPopout ? (
                                            <PopoutButton
                                                text={buttonText}
                                                variant={buttonType || 'contained'}
                                                customSectionKey={popoutCustomSection}
                                                params={params}
                                                aria-label={ariaLabel}
                                                disabled={content.requestConsent && !checkBoxChecked}
                                                css={classes.fullWidth}
                                            />
                                        ) : (
                                            <CMSLink
                                                type='button'
                                                variant={buttonType || 'contained'}
                                                href={buttonLinkPath}
                                                target={getLinkTargetValue(buttonTarget)}
                                                aria-label={ariaLabel}
                                                buttonClassName={buttonClassName}
                                                data-testid='ButtonId'
                                                disabled={content.requestConsent && !checkBoxChecked}
                                                css={classes.fullWidth}
                                            >
                                                {buttonText}
                                            </CMSLink>
                                        )}
                                    </Grid>
                                ) : null
                        )}
                    </Grid>
                </div>
            ) : null}
        </Grid>
    );
}
