import React from 'react';
import { IconButton, Grid } from '@mui/material';
import { ratingSelectStyles as classes } from './styleObjects/RatingSelect';
import { StarIcon } from '../../../IconComponents/StarIcon';
import { v2Colors } from '@web-for-marketing/react-core';

interface RatingSelectProps {
    rating?: number;
    starColor?: string;
    starSize?: number;
    totalStars?: number;
    clickable?: boolean;
    setRating?: (rating: number) => void;
}

export function RatingSelect({
    setRating,
    rating = 1,
    totalStars = 5,
    clickable = false,
    starColor = v2Colors.text.activePrimary,
    starSize = 24,
}: RatingSelectProps): JSX.Element {
    return (
        <Grid data-testid='starsContainer'>
            {Array.from(Array(totalStars), (_val, i) =>
                clickable && setRating ? (
                    <IconButton onClick={() => setRating(i + 1)} key={i} size='large'>
                        <StarIcon
                            css={classes.star(starSize)}
                            color={i + 1 > rating ? v2Colors.core.haze : starColor}
                        />
                    </IconButton>
                ) : (
                    <StarIcon
                        key={i}
                        css={[classes.starContainer, classes.star(starSize)]}
                        color={i + 1 > rating ? v2Colors.core.haze : starColor}
                    />
                )
            )}
        </Grid>
    );
}
