import React from 'react';
import { featureIconsStyles as classes } from '../styleObjects/FeatureIcons';
import { ParseAbbreviations } from '../../ParseAbbreviations';
import { Heading } from '@web-for-marketing/react-ui';
import { Picture } from '@components/Picture';
import { SmallStackFeature } from '@models/section/featuresSection';
import { TextAlignValues } from '@models/editorForm';
import { RichTextContent } from '@components/RichTextContent';
import { CMSLink } from '@components/CMSLink';
import { getLinkTargetValue } from '@sections/helpers/SectionComponentHelper';

interface SmallStackItemProps {
    lazyLoad: boolean;
    alignFeatures: TextAlignValues;
    feature: SmallStackFeature;
    shouldIncreaseHeadingLevel: boolean;
}

export function SmallStackItem({
    feature,
    alignFeatures,
    lazyLoad,
    shouldIncreaseHeadingLevel,
}: SmallStackItemProps): JSX.Element {
    const { image, title, body, link, target } = feature;

    return (
        <div data-testid='smallStackItem'>
            <Picture
                fullWidth={false}
                imagePath={image.imagePath}
                mobilePath={image.mobilePath ? image.mobilePath : ''}
                alt={image.imageAlt}
                mobileAlt={image.mobileAlt}
                lazyLoad={lazyLoad}
                css={[classes.media, classes[`${alignFeatures}Image`]]}
            />
            {title ? (
                <Heading
                    variant='h4'
                    color='tertiary'
                    component={shouldIncreaseHeadingLevel ? 'h2' : 'h3'}
                    css={classes[`${alignFeatures}Heading`]}
                >
                    {link ? (
                        <CMSLink
                            type='link'
                            rel='noopener noreferrer'
                            title={title}
                            target={getLinkTargetValue(target)}
                            href={link}
                            css={classes.featureLinks}
                        >
                            <ParseAbbreviations content={title} />
                        </CMSLink>
                    ) : (
                        <ParseAbbreviations content={title} />
                    )}
                </Heading>
            ) : null}
            <RichTextContent
                content={body ? ParseAbbreviations({ content: body, stringFormat: true }) : ''}
                css={classes[`${alignFeatures}Body`]}
            />
        </div>
    );
}
