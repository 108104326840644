import { httpGet } from './apiService';
import { OtherVideo, Video } from '@models/video';
import { PagedResult } from '@models/pagination';

const videoApiUrl = '/api/video';

export function getOtherVideos(languageId: number, video: Video): Promise<OtherVideo> {
    return httpGet(`${videoApiUrl}/other-videos/${languageId}/${video.id}`).then((result) => result.json());
}

export function getVideosByTagId(tagIds: number[], page = 1, maxRecordsPerPage = 9): Promise<PagedResult<Video>> {
    const ids = tagIds.map((tagId) => `tagIds=${tagId}`).join('&');
    return httpGet(`${videoApiUrl}/paged/tag/?${ids}&page=${page}&maxRecordsPerPage=${maxRecordsPerPage}`).then(
        (result) => result.json()
    );
}
