import { breakpoints, v2Colors } from '@web-for-marketing/react-core';

export const classes = {
    arrowButton: {
        padding: '1rem !important',
        '&:first-child': {
            marginRight: '2.5rem',
        },
        '& svg': {
            color: 'currentColor',
            width: '3rem',
            height: '3rem',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'none',
        },
    },
    carouselFixedContainer: {
        position: 'relative',
        overflow: 'hidden',
        [`@media (min-width: ${breakpoints.md}px)`]: {
            '&::after': {
                content: '""',
                width: '5rem',
                background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                height: '100%',
                position: 'absolute',
                right: 0,
                top: 0,
            },
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginRight: '-3.6rem',
            marginLeft: '-3.6rem',
        },
    },
    scrollingWrapper: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: '30%',
        transform: 'translateX(30%)',
        overflowX: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            gridAutoColumns: '45%',
            transform: 'translateX(45%)',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '0 2rem',
            scrollPaddingLeft: '2rem',
            scrollPaddingRight: '70%',
            scrollSnapType: 'x mandatory',
            gridAutoColumns: '40%',
            transform: 'translateX(40%)',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            scrollPaddingRight: '55%',
            gridAutoColumns: '70%',
            transform: 'translateX(70%)',
        },
    },
    idle: {
        transform: 'none !important',
        transition: 'transform 0.5s cubic-bezier(0.23, 1, 0.32, 1)',
    },
    reverse: {
        transform: 'translateX(-30%)',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            transform: 'translateX(-45%)',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            transform: 'translateX(-40%)',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            transform: 'translateX(-70%)',
        },
    },
    card: {
        backgroundColor: v2Colors.core.snow,
        maxHeight: '50rem',
        border: '1px solid #C7CBD2',
        borderRadius: '10px',
        boxShadow: '0 0 0 rgb(102 120 140 / 10%), 0 0 0 rgb(102 120 140 / 10%)',
        transition: 'transform ease 500ms, box-shadow ease 500ms',
        margin: '1rem 0',
        scrollSnapAlign: 'end',
        marginRight: '6rem',
        '&:hover': {
            boxShadow: '0 0 .6rem rgba(102,120,140,0.1), 0 1rem 1rem rgba(102,120,140,0.1)',
            transform: 'translateY(-1rem)',
        },
        '&:focus': {
            boxShadow: '0 0 .6rem rgba(102,120,140,0.1), 0 1rem 1rem rgba(102,120,140,0.1)',
            borderColor: v2Colors.core.innovation,
            outline: 'none',
        },
        '@media (max-width: 1499.9px)': {
            marginRight: '4.5rem',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            '&:last-of-type': {
                marginRight: 0,
            },
            '&:not(:last-of-type)': {
                marginRight: '2.5rem',
            },
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            '&:not(:last-of-type)': {
                marginRight: '2rem',
            },
        },
    },
    cardPlaceholder: {
        height: '45rem',
    },
    cardPlaceholderImage: {
        height: '40%',
        width: '100%',
        borderRadius: '10px 10px 0 0',
        backgroundColor: v2Colors.background.light,
    },
    cardTextContainer: {
        padding: '3rem 3rem 4rem',
        '& > *:not(:last-child)': {
            marginBottom: '1rem',
        },
    },
    cardTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '4',
    },
    viewAll: {
        transition: 'background-color 500ms ease',
        borderRadius: '10px',
        padding: '1rem',
        '&:hover,&:focus': {
            backgroundColor: v2Colors.action.active,
            outline: 'none',
        },
    },
    title: {
        marginBottom: '3rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
        },
    },
    body: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
        },
    },
    picture: {
        borderRadius: '10px 10px 0 0',
        height: '16rem',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            height: '12rem',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            height: '10rem',
        },
    },
} as const;
