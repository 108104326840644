import React, { useState, useRef } from 'react';
import { Button } from '@mui/material';
import { SubNavigationData } from '@models/section/subNavigationSection';
import { subNavigationMobileStyles as classes } from '@components/styleObjects/SubNavigationMobile';
import { CMSLink } from '@components/CMSLink';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { NewTabIcon } from '@web-for-marketing/react-ui';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { v2Colors } from '@web-for-marketing/react-core';
import { DropdownIcon } from '@components/IconComponents/DropdownIcon';
import { useAccessiblePopout } from '@web-for-marketing/react-hooks';

export interface SubNavigationMobileProps {
    sectionData: SubNavigationData;
}

export function SubNavigationMobile({ sectionData }: SubNavigationMobileProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const [selected, setIsSelected] = useState(sectionData.links[0].linkText);

    const handleClose = (): void => setOpen(false);
    const popover = useRef(null);

    useAccessiblePopout(popover, open, handleClose, {
        disableScroll: false,
    });

    const handleClick = (): void => {
        setOpen((prevState) => !prevState);
    };

    return (
        <div ref={popover}>
            <div css={classes.subNavigation}>
                <Button
                    id='sub-navigation-menu-button'
                    aria-label={open ? 'Click to close sub navigation menu' : 'Click to open sub navigation menu'}
                    onClick={handleClick}
                    css={classes.dropDownButton}
                    disableRipple
                >
                    {selected}
                    <DropdownIcon color={v2Colors.text.activePrimary} css={open ? classes.rotate : ''} />
                </Button>
            </div>
            {open ? (
                <div css={classes.dropDownMenu} aria-label='Sub navigation menu' aria-hidden={!open} role='dialog'>
                    {sectionData.links.map((link, index) => (
                        <div key={`${JSON.stringify(link)}-${index}`} css={classes.linkItemContainer}>
                            <CMSLink
                                href={link.linkPath}
                                target={getLinkTargetValue(link.linkTarget)}
                                css={classes.linkItem}
                                type='link'
                                onClick={() => {
                                    setIsSelected(link.linkText);
                                    handleClose();
                                }}
                            >
                                {link.linkText}
                                {link.linkTarget ? (
                                    <NewTabIcon aria-hidden='true' css={classes.smallLeftMarginIcon} />
                                ) : null}
                            </CMSLink>
                        </div>
                    ))}
                    {sectionData.buttons.length ? (
                        <>
                            {sectionData.buttons.map(
                                (
                                    {
                                        buttonText,
                                        buttonLinkPath,
                                        buttonTarget,
                                        buttonClassName,
                                        ariaLabel,
                                        isPopout,
                                        popoutCustomSection,
                                        params = [],
                                    },
                                    index
                                ) =>
                                    buttonText ? (
                                        <div
                                            css={[classes.linkItemContainer, classes.buttonContainer]}
                                            key={`${index}${buttonText}${popoutCustomSection}`}
                                        >
                                            {isPopout ? (
                                                <PopoutButton
                                                    text={buttonText}
                                                    variant='text'
                                                    customSectionKey={popoutCustomSection}
                                                    params={params}
                                                    aria-label={ariaLabel}
                                                    css={classes.buttonItem}
                                                    disableFocusRipple
                                                    onClick={handleClose}
                                                />
                                            ) : (
                                                <CMSLink
                                                    type='button'
                                                    variant='text'
                                                    href={buttonLinkPath}
                                                    target={getLinkTargetValue(buttonTarget)}
                                                    aria-label={ariaLabel}
                                                    buttonClassName={buttonClassName}
                                                    css={classes.buttonItem}
                                                    data-testid='ButtonId'
                                                    disableFocusRipple
                                                    onClick={handleClose}
                                                >
                                                    {buttonText}
                                                </CMSLink>
                                            )}
                                        </div>
                                    ) : null
                            )}
                        </>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}
