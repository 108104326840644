import React from 'react';
import { ColorBackground } from '@models/layeredBackground';
import { getRgbaColor } from '@web-for-marketing/react-core';

export interface LayeredColorBackgroundProps {
    background: ColorBackground;
}

export function LayeredColorBackground({ background }: LayeredColorBackgroundProps): JSX.Element {
    return (
        <div
            data-testid='layered-color-background'
            css={{
                position: 'absolute',
                inset: 0,
                backgroundColor: getRgbaColor(background.config.color, background.config.transparency),
            }}
        />
    );
}
